/*
// 引入封装的第一层axios接口
import req from '@/utils/req.js'

//直播课程报名
export const msubscribe_api = (params) => {
    return req.post('/blade-resources/subscribe/startSubscribe', params)
}

//取消直播课程报名
export const subscribeCancel_api = (id) => {
    return req.post('/blade-resources/subscribe/subscribeCancel', id)
}
//录播课程详情
export const recordDetail_api = (params) => {
    return req.get('/blade-resources/telecast/recordDetail', params)
}
//直播课程详情
export const liveDetail_api = (id) => {
    return req.get('/blade-resources/telecast/detail', id)
}
// //查看用户是否报名该门直播课程
// export const reportDetail_api = (id) => {
//     return req.get('/blade-resources/subscribe/detail', id)
// }


//互动
export const saveTelecastChat_api = (params) => {
    return req.post('/api/blade-resources/yunBo/saveTelecastChat', params)
}


*/


import request from "@/utils/req";

export const msubscribe_api = (params) => {
    return request({
        url: '/api/blade-resources/subscribe/startSubscribe',
        method: 'post',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export const subscribeCancel_api = (params) => {
    return request({
        url: '/api/blade-resources/subscribe/subscribeCancel',
        method: 'post',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}
export const recordDetail_api = (params) => {
    return request({
        url: '/api/blade-resources/telecast/recordDetail',
        method: 'get',
        params: {
            ...params
        }
    })
}
export const liveDetail_api = (params) => {
    return request({
        url: '/api/blade-resources/telecast/detail',
        method: 'get',
        params: {
            ...params
        },
    })
}
export const reportDetail_api = (params) => {
    return request({
        url: '/api/blade-resources/subscribe/detail',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const saveTelecastChat_api = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/saveTelecastChat',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}
