// 引入封装的第一层axios接口
import request from "@/utils/req";

/*// 获取userSig
export const getUserSig = (params) => {
    return req.get('blade-resources/yunBo/getUserSig', params)
}*/

export const getUserSig = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/getUserSig',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 直播点赞
export const saveResourcesLike_api = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/saveResourcesLike',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

// 查询云直播
export const getYunTelecast_api = (telecastId) => {
    return request({
        url: '/api/blade-resources/yunBo/getYunTelecast',
        method: 'get',
        params: telecastId,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;"
        }
    })
}
/* 查询教师上传的录播资源（做直播用） */
export const getYunTelecastLubo_api = (telecastId) => {
    return request({
        url: '/api/blade-resources/data/getResourcesDataByTelecastId',
        method: 'get',
        params: telecastId,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;"
        }
    })
}
/* 提交观看时长 */
export const saveTelecastTeacherHour_api = (params) => {
    return request({
        url: '/api/blade-resources/telecast/saveTelecastTeacherHour',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}
/* 查询1个学时需要多少秒 */
export const getLength_api = (params) => {
    return request({
        url: '/api/blade-resources/telecast/getLength',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}
// 创建房间号
export const getRoomId = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/getMath',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

// 创建直播
export const createLive = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/saveYunTelecast',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

// 直播结束
export const liveEnd = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/endTelecastTime',
        method: 'get',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

//添加互动信息
export const saveTelecastChat_api = (params) => {
    return request({
        url: '/api/blade-resources/yunBo/saveTelecastChat',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

